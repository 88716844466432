@font-face {
  font-family: 'icomoon';
  src:
    url(font_path('icomoon.ttf')) format('truetype'),
    url(font_path('icomoon.woff')) format('woff'),
    url(font_path('icomoon.svg#icomoon')) format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_down:before {
  content: "\e900";
}
.icon-arrow_left:before {
  content: "\e901";
}
.icon-arrow_right:before {
  content: "\e902";
}
.icon-arrow_up:before {
  content: "\e903";
}
.icon-check:before {
  content: "\e90e";
}
.icon-bubbles:before {
  content: "\e96d";
}
.icon-sad:before {
  content: "\e9e5";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin:before {
  content: "\eaca";
}
