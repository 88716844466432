.main-form {
  margin-bottom: 40px;
  padding: 45px 65px 15px;
  max-width: 465px;
  background-color: white;
  border-radius: 25px;
}
.main-form__title {
  margin-bottom: 10px;
  color: $vane-blue;
  font-family: "Poppins-SemiBold";
  font-size: 22px;
  font-weight: 400;
}
.main-form__row {
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 1.75;
}
.main-submit__btn {
  margin-bottom: 30px;
  width: 100%;
}
.login-submit__btn {
  margin-bottom: 20px;
  width: 100%;
}
.main-form--dialog {
  margin-bottom: 0;
  padding: 0;
}
.main-form__info {
  margin-bottom: 10px;
  color: $vane-blue;
  font-family: "Poppins-Regular";
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  line-height: 1.7em
}
