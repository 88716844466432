.main-header {
  padding-top: 15px;
  background-color: $vane-blue;
}
.main-header__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 65px;
}
.main-header__logo-link {
  display: inline-block;
  margin-bottom: 15px;
  max-width: 120px;
}
.head-section__arrow-wrap {
  position: relative;
  margin-bottom: 40px;
  text-align: right;
  z-index: 1;
}
@media screen and (max-width: $screen-xs-max) {
  .head-section__text-wrap {
    padding-top: 305px;
  }
  .head-section__arrow-wrap {
    display: none;
  }
}
