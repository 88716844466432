.input {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-top: 2.15em;
  vertical-align: top;
  z-index: 1;
  overflow: hidden;
}
.input__field {
  position: relative;
  display: block;
  padding: 0.85em 0.15em;
  width: 100%;
  border: none;
  border-radius: 0;
  background: transparent;
  color: $grey-900;
  font-size: 18px;
  -webkit-appearance: none;
  &:focus {
    outline: none;
  }
  &:invalid {
    box-shadow: none;
  }
}
.select__field {
  position: relative;
  display: block;
  padding: 0.85em 0.15em;
  width: 100%;
  border-radius: 20px;
  color: $grey-800;
  font-size: 18px;
}
.input__label {
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  padding: 0 0.25em;
  width: 100%;
  height: calc(100% - 1em);
  color: $grey-400;
  font-family: "Poppins-Regular";
  font-weight: 300;
  font-size: 18px;
  pointer-events: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  user-select: none;
}
.input__label-content {
  position: absolute;
  left: 2px;
  display: block;
  padding: 1.6em 0 0;
  width: 100%;
}
.input__label::before,
.input__label::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 10px);
  border-bottom: 1px solid $grey-400;
}
.input__label::after {
  top: -1px;
  border-bottom: 4px solid $vane-blue;
  transform: translate3d(-101%, 0, 0);
  transition: transform 0.3s;
}
.input__field:focus + .input__label::after,
.input--filled .input__label::after {
  transform: translate3d(0, 0, 0);
}
// Duplication required for Firefox, it ignores the entire declaration block
// because of the selector
.input__field:-webkit-autofill + .input__label::after {
  transform: translate3d(0, 0, 0);
}
.input__field:focus + .input__label .input__label-content,
.input--filled .input__label-content {
  animation: animation-input 0.3s forwards;
}
.input__field:-webkit-autofill + .input__label .input__label-content {
  animation: animation-input 0s forwards;
}
@keyframes animation-input {
  50% {
    opacity: 0;
    transform: translate3d(1em, 0, 0);
  }
  51% {
    opacity: 0;
    transform: translate3d(-1em, -40%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, -40%, 0);
  }
}
