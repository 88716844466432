.login__wrap {
  padding-top: 75px;
  min-height: 100vh;
  position: relative;
  background-color: #3A2FF5;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.9;
  }
  @media screen and (max-width: $screen-xs-max) {
    padding-top: 30px;
    background-image: url(image_path("vane_background.jpg"));
    background-position: center;
  }
  .logo__link--large {
    max-width: 88px;
  }
  .flash {
    padding-top: 1em;
    font-family: "Poppins-Semibold";
    font-weight: 300;
    font-size: 18px;
    color: #9b9b9b;
  }
  .flash-alert, .flash-error {
    color: red;
  }
}
.login__head {
  position: relative;
  margin-bottom: 40px;
  z-index: 1;
  text-align: left;
  @media screen and (max-width: $screen-xs-max) {
    margin-bottom: 25px;
  }
}
.login__body {
  position: relative;
  z-index: 1;
}
.main-form--login {
  padding-bottom: 45px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: $screen-xs-max) {
    padding: 45px 25px 50px;
  }
}
.forgot-password__link {
  margin-bottom: 10px;
  color: $grey-400;
  font-family: "Poppins-Regular";
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  &:hover {
    color: $grey-800;
  }
}
.dialog-forgot {
  min-height: initial;
  .dialog__content-head {
    margin-bottom: 0;
  }
}
.dialog-forgot__submit {
  width: 100%;
  font-family: "Poppins-Regular";
  font-size: 18px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 25px;
  margin-bottom: 65px;
}
#contact-form-success {
  display: none;
}
