.main-footer {
  padding-top: 85px;
  background-color: $vane-blue;
  .main-footer__logo {
    margin-bottom: 55px;
    @media screen and (max-width: $screen-xs-max) {
      margin-bottom: 30px;
    }
  }
}
.main-footer__head {
  display: flex;
  justify-content: space-between;
}
.main-footer__head-btn {
  display: inline-block;
  margin-top: 25px;
  background-color: transparent;
  border: none;
  color: white;
  text-decoration: none;
  font-size: 20px;
  transition: transform 150ms ease;
  &:hover {
    transform: scale(1.1);
  }
  @media screen and (max-width: $screen-xs-max) {
    display: none;
  }
}
.main-footer__text-wrap {
  margin-bottom: 45px;
  @media screen and (max-width: $screen-xs-max) {
    margin-bottom: 35px;
  }
}
.main-footer__text {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
  color: white;
  margin-bottom: 15px;
}
.sitemap__wrap {
  padding-top: 40px;
}
.sitemap__title {
  margin-bottom: 10px;
  font-family: "Poppins-Regular";
  font-size: 18px;
  line-height: 26px;
  color: $blue-900;
  font-weight: 500;
}
.sitemap__list {
  margin-bottom: 40px;
}
.sitemap__li {
  display: block;
  margin-bottom: 5px;
}
.sitemap__link {
  display: inline-block;
  font-family: "Poppins-Regular";
  font-size: 18px;
  color: white;
  font-weight: 400;
  text-decoration: none;
  &:hover, &:focus {
    color: #dadada;
  }
}
.footer-social__nav {
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
}
.footer-social__nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  float: left;
  width: 32px;
  height: 32px;
  color: white;
  border-radius: 50%;
  overflow: hidden;
  text-decoration: none;
  font-size: 18px;
  &:not(:last-child) {
    margin-right: 15px;
  }
}
.footer-social__nav-link--circle {
  background-color: white;
  color: $vane-blue;
}
.footer-copyright {
  .section-text {
    font-size: 12px;
    line-height: 1.4;
  }
}
.main-form--footer {
  min-height: 545px;
}

@media screen and (max-width: $screen-xs-max) {
  .main-footer {
    padding-top: 45px;
    padding-bottom: 45px;
    .section-text {
      font-size: 17px;
    }
  }
  .main-form--footer {
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    max-width: 100%;
    background-color: $vane-blue;
    font-family: "Poppins-Regular";
    .main-form__title,
    .input__label,
    .input__field {
      color: white;
    }
    .input__label{
      &:before,
      &:after {
        border-color: white;
      }
    }
  }
  .sitemap__wrap {
    padding-top: 0;
  }
  .sitemap__title {
    font-size: 17px;
    font-weight: 400;
  }
  .footer-social__nav {
    text-align: center;
  }
  .footer-social__nav-link {
    display: inline-block;
    float: none;
    margin-left: 20px !important;
    margin-right: 20px !important;
    width: 40px;
    height: 40px;
    line-height: 44px;
    font-size: 24px;
  }
  .footer-copyright {
    text-align: center;
    .section-text {
      font-size: 13px;
      line-height: 1.6;
    }
  }
}
