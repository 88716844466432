body {
  position: relative;
  background-color: white;
  &.mobile-menu--open {
    overflow: hidden;
  }
}
.page-title {
  margin-bottom: 20px;
  color: white;
  font-family: $lato-font;
  font-size: 36px;
  line-height: 1.2;
  font-weight: 400;
}
.main-section {
  padding-top: 50px;
  background-color: white;
}
.section-title {
  margin-bottom: 25px;
  color: $vane-blue;
  font-family: $lato-font;
  font-size: 32px;
  font-weight: 400;
}
.section-subtitle {
  max-width: 550px;
  margin-bottom: 50px;
  color: $grey-900;
  font-family: $lato-font;
  font-size: 32px;
  font-weight: 300;
}
.section-text {
  margin-bottom: 20px;
  color: $grey-900;
  font-family: "Poppins-Regular";
  font-size: 16px;
  line-height: 1.75;
  font-weight: 400;
}
.main-content {
  position: relative;
}
@media screen and (max-width: $screen-xs-max) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .page-title {
    font-size: 26px;
  }
  .main-section {
    padding-top: 22px;
  }
  .section-title {
    margin-bottom: 10px;
    font-size: 21px;
  }
  .section-subtitle {
    font-size: 19px;
    font-weight: 400;
  }
  .section-text {
    font-size: 14px;
    line-height: 1.35;
  }
}
