$app-colors: (
  'white': white,
  orange-400: #fa7236
);

@each $color in $app-colors {
  .in-#{nth($color, 1)} {
    color: nth($color, 2) !important;
  }
  .bg-#{nth($color, 1)} {
    background-color: nth($color, 2) !important;
  }
}

@function color($color) {
  @return map-get($app-colors, $color)
}

$grey-400 : #9b9b9b;
$grey-800 : #555555;
$grey-900 : #4a4a4a;

$vane-blue : #3A2FF5;
$blue-900 : #001f4b;
