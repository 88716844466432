.main-checkbox__label {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}
.main-checkbox__input {
  opacity: 0;
  position: absolute;
}
.main-checkbox__ico {
  position: absolute;
  top: 2px;
  left: 0;
  width: 14px;
  height: 14px;
  background-color: white;
  border: 1px solid $grey-400;
  transition: all 150ms ease-in-out;
  &:after {
    content: '\e90e';
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    font-family: 'icomoon';
    font-size: 6px;
    transition: opacity 150ms ease-in-out;
    transform: translate(-50%, -50%);
  }
}
.main-checkbox__text {
  color: $grey-400;
  font-family: "Poppins-Regular";
  font-size: 14px;
  font-weight: 400;
  user-select: none;
}
.main-checkbox__input:checked + .main-checkbox__ico {
  border-color: $vane-blue;
  background-color: $vane-blue;
  &:after {
    opacity: 1;
  }
}
