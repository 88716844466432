.not-found__wrap {
  min-height: 100vh;
  background-color: $vane-blue;
}
.not-found__logo-wrap {
  padding-top: 25px;
  margin-bottom: 140px;
}
.not-found__heading {
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.go-back__btn {
  position: relative;
  padding: 5px 5px 5px 30px;
  background-color: transparent;
  text-decoration: none;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
}
.go-back__btn-ico {
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 24px;
  transform: translateY(-50%);
}
.not-found__smile-ico {
  font-size: 24px;
  color: white;
}
.not-found__title {
  color: white;
  font-family: "Poppins-Regular";
  font-size: 90px;
  font-weight: 300;
  margin-bottom: 40px;
}
.not-found__link {
  display: inline-block;
  font-size: 18px;
  color: $grey-800;
  font-family: "Poppins-Regular";
  background-color: white;
  border: none;
  text-decoration: none;
  padding: 15px 52px;
  margin-bottom: 20px;
}
@media screen and (max-width: $screen-xs-max) {
  .not-found__logo-wrap {
    margin-bottom: 50px;
  }
  .not-found__heading {
    margin-bottom: 70px;
  }
  .not-found__title {
    font-size: 34px;
  }
}
