.main-button {
  position: relative;
  display: inline-block;
  padding: 15px 25px;
  min-width: 180px;
  border: none;
  background-color: transparent;
  font-family: "Poppins-Regular";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.1px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  z-index: 1;
  transition: border-color 0.3s, color 0.3s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  &:before {
    content: '';
    position: absolute;
  	top: 0;
  	left: 0;
  	width: 150%;
  	height: 100%;
  	z-index: -1;
    transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
    transform-origin: 0% 100%;
    transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
  }
  &:hover:before {
    opacity: 1;
  	transform: rotate3d(0, 0, 1, 0deg);
  	transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }
}
.main-button--white {
  color: $grey-800;
  background-color: white;
  &:before {
    background-color: #e3f2fd;
  }
  &:hover, &:focus {
    color: $grey-800;
  }
}
.main-button--blue {
  color: white;
  background-color: $vane-blue;
  &:before {
    background-color: #304ffe;
  }
  &:hover, &:focus {
    color: white;
  }
}
.main-button--dark-blue {
  color: white;
  background-color: $blue-900;
  &:before {
    background-color: black;
  }
}
.main-button--small {
  min-width: 80px;
  padding: 10px 10px;
  min-height: 20px;
}
.rounded-corners {
  border-radius: 50px;
}
.main-button--xs-full {
  @media screen and (max-width: $screen-xs-max) {
    width: 100%;
  }
}
.mobile-nav__btn {
  display: none;
  position: relative;
  margin-bottom: 15px;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  z-index: 11;
  @media screen and (max-width: $screen-sm-max) {
    display: inline-block;
  }
}
.mobile-nav__btn-bar {
  position: absolute;
  top: 50%;
  left: 2px;
  right: 2px;
  display: inline-block;
  height: 2px;
  background-color: white;
  transform: translateY(-50%);
  transition: all 150ms ease-in-out;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    transform-origin: center;
    transition: all 150ms ease-in-out;
  }
  &:before {
    top: -8px;
  }
  &:after {
    bottom: -8px;
  }
}
.mobile-nav__btn--open {
  .mobile-nav__btn-bar {
    background-color: transparent;
    &:before {
      top: 0;
      transform: rotate(45deg);
    }
    &:after {
      bottom: 0;
      transform: rotate(-45deg);
    }
  }
}
