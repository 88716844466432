@import 'bootstrap3-sass/bootstrap';

@import 'base_v2/variables';
@import 'base_v2/typography';
@import 'base_v2/icomoon';
@import 'base_v2/colors';
@import 'base_v2/custom_reset';

@import 'base_v2/forms';
@import 'base_v2/buttons';
@import 'base_v2/inputs/module';
@import 'base_v2/dialogs';

@import 'base_v2/general';
@import 'base_v2/header';
@import 'base_v2/footer';
//
// @import "modules/fixed_squares";
@import "base_v2/head_section";
// @import "modules/partner_block";
// @import "modules/start_financing";
// @import "modules/finance_media";
// @import "modules/comment";
// @import "modules/partner_info";
// @import "modules/about_us";
// @import "modules/join_us";
// @import "modules/cookies_module";
//
@import "pages_v2/404";
// @import "pages/privacy-policy_page";
@import "pages_v2/login";
@import "shared/fonts";
//
// @import "utils/color_helpers";
// @import "utils/helpers";
