.dialog,
.dialog__overlay {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
.dialog {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
  z-index: 1000;
}
.dialog__overlay {
	position: absolute;
	z-index: 1;
	background-color: rgba(0, 30, 75, 0.5);
	opacity: 0;
	transition: opacity 0.3s;
}
.dialog--open .dialog__overlay {
	opacity: 1;
	pointer-events: auto;
}
.dialog__content {
  position: relative;
  padding: 45px 65px 0;
	width: 50%;
	max-width: 460px;
	min-width: 290px;
  min-height: 530px;
	background: #fff;
	opacity: 0;
	z-index: 100;
  @media screen and (max-width: $screen-xs-max) {
    padding: 25px 30px 0;
  }
}
.dialog--open .dialog__content {
	pointer-events: auto;
}
.dialog.dialog--open .dialog__content,
.dialog.dialog--close .dialog__content {
	animation-duration: 0.3s;
	animation-fill-mode: forwards;
}
.dialog.dialog--open .dialog__content {
	animation-name: anim-open;
}
.dialog.dialog--close .dialog__content {
	animation-name: anim-close;
}
@keyframes anim-open {
	0% { opacity: 0; transform: scale3d(1.1, 1.1, 1); }
	100% { opacity: 1; transform: scale3d(1, 1, 1); }
}
@keyframes anim-close {
	0% { opacity: 1; }
	100% { opacity: 0; transform: scale3d(0.9, 0.9, 1); }
}
.dialog__content-head {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.dialog__content-title {
  margin-bottom: 25px;
  color: $vane-blue;
  font-family: "Poppins-Regular";
  font-size: 22px;
  font-weight: 400;
}
.dialog__content-text {
	font-size: 22px;
	color: $grey-900;
	margin-bottom: 10px;
}
.dialog__btn-close {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.75;
  outline: none;
  &:hover {
    opacity: 1;
  }
}
.dialog__btn-close-line {
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  width: 1px;
  transform: translateX(-50%);
  background-color: black;
  transform: rotate(45deg);
  outline: none;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: inherit;
    transform: rotate(90deg);
  }
}


html.remodal-is-locked {
  overflow: hidden;
  touch-action: none;
}
.remodal,
[data-remodal-id] {
  display: none;
}
.remodal-overlay {
  position: fixed;
  z-index: 9999;
  top: -5000px;
  right: -5000px;
  bottom: -5000px;
  left: -5000px;
  display: none;
}
.remodal-wrapper {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: auto;
  text-align: center;
  -webkit-overflow-scrolling: touch;
}
.remodal-wrapper:after {
  display: inline-block;
  height: 100%;
  margin-left: -0.05em;
  content: "";
}
.remodal-overlay,
.remodal-wrapper {
  backface-visibility: hidden;
}
.remodal {
  position: relative;
  margin-bottom: 10px;
  outline: none;
  text-size-adjust: 100%;
  text-align: left;
}
.remodal-is-initialized {
  display: inline-block;
}
.remodal-bg.remodal-is-opening,
.remodal-bg.remodal-is-opened {
  filter: blur(3px);
}
.remodal-overlay {
  background-color: rgba(0, 30, 75, 0.5);
}
.remodal-overlay.remodal-is-opening,
.remodal-overlay.remodal-is-closing {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
.remodal-overlay.remodal-is-opening {
  animation-name: remodal-overlay-opening-keyframes;
}
.remodal-overlay.remodal-is-closing {
  animation-name: remodal-overlay-closing-keyframes;
}
.remodal-wrapper {
  padding: 10px 10px 0;
}
.remodal {
  padding: 45px 65px 0;
	width: 100%;
	max-width: 460px;
	min-width: 290px;
  min-height: 530px;
  transform: translate3d(0, 0, 0);
  background-color: #fff;
}
.remodal.remodal-is-opening,
.remodal.remodal-is-closing {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
.remodal.remodal-is-opening {
  animation-name: remodal-opening-keyframes;
}
.remodal.remodal-is-closing {
  animation-name: remodal-closing-keyframes;
}
.remodal,
.remodal-wrapper:after {
  vertical-align: middle;
}

@keyframes remodal-opening-keyframes {
  0% { opacity: 0; transform: scale3d(1.1, 1.1, 1); }
  100% { opacity: 1; transform: scale3d(1, 1, 1); }
}
@keyframes remodal-closing-keyframes {
  0% { opacity: 1; }
	100% { opacity: 0; transform: scale3d(0.9, 0.9, 1); }
}
@keyframes remodal-overlay-opening-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes remodal-overlay-closing-keyframes {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
