.remodal-is-locked .head-section__header {
  padding-right: 15px;
}
.head-section {
  position: relative;
  min-height: 100vh;
  background-image: url("../img/head-section_img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.44;
    background-color: rgba(53, 57, 79, 0.9);
  }
  @media screen and (max-width: $screen-sm-max) {
    background-image: url("../img/head-section_img_320.jpg");
    background-position: center;
  }
}
.head-section__header {
  position: fixed;
  padding-top: 75px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transition: 0.3s ease-in-out;
  transition-property: height, background-color, padding-top, top;
  @media screen and (max-width: $screen-sm-max) {
    padding-top: 50px;
  }
}
.head-section__header--active {
  height: 74px;
  background-color: $vane-blue;
  padding-top: 20px;
  .main-header__nav-list,
  .logo__link {
    margin-bottom: 20px;
  }
  .main-header__nav-link--blue:hover {
    color: #001f4b;
  }
  .logo__link--large {
    max-width: 88px;
  }
  @media screen and (max-width: $screen-sm-max) {
    .head-section__header-wrap {
      padding-top: 5px;
    }
  }
  @media screen and (min-width: $screen-md-min) {
    .logo__link--large {
      max-width: 120px;
    }
  }
}
.head-section__header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.logo__link {
  display: inline-block;
  transition: 0.3s max-width ease-in-out;
  margin-bottom: 15px;
}
.logo__link--large {
  max-width: 135px;
}
@media screen and (max-width: $screen-sm-max) {
  .logo__link--large {
    max-width: 88px;
  }
}
.logo__link--small {
  max-width: 120px;
}
.logo__img,
.main-header__logo {
  width: 200%;
  height: auto;
}
.main-header__nav-list {
  display: inline-block;
  list-style: none;
  position: relative;
  margin-bottom: 15px;
  &.hover {
    .main-header__nav-line {
      transition-duration: 0.5s;
    }
    .main-header__nav-li:hover ~ .main-header__nav-line {
      opacity: 1;
    }
    .main-header__nav-li {
      &:nth-child(1):hover ~ .main-header__nav-line {
      	transform: translate3d(0, 0, 0);
        width: 59px;
      }
      &:nth-child(2):hover ~ .main-header__nav-line {
      	transform: translate3d(89px, 0, 0);
        width: 93px;
      }
      &:nth-child(3):hover ~ .main-header__nav-line {
      	transform: translate3d(213px, 0, 0);
        width: 78px;
      }
      &:nth-child(4):hover ~ .main-header__nav-line {
      	transform: translate3d(320px, 0, 0);
        width: 43px;
      }
      &:nth-child(5):hover ~ .main-header__nav-line {
    	  transform: translate3d(394px, 0, 0);
        width: 55px;
      }
    }
  }
  @media screen and (max-width: $screen-sm-max) {
    display: block;
    margin-bottom: 0;
    .main-header__nav-li {
      margin-bottom: 37px !important;
    }
    .main-header__nav-li--mobile-btn {
      margin-bottom: 20px;
      background-color: $vane-blue;
      padding-top: 16px;
      padding-bottom: 16px;
      .main-header__nav-link {
        color: white !important;
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
}

.main-header__nav-list-wrap {
  position: relative;
  display: inline-block;
  @media screen and (max-width: $screen-sm-max) {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 135px 30px 0;
    background-color: transparentize(rgba(53, 57, 79, 0.9), .05);
    z-index: 10;
    overflow-y: auto;
  }
}
.main-header__nav-list-wrap--open {
  display: block;
}
.main-header__nav-li {
  float: left;
  display: block;
  color: white;
  font-family: "Poppins-Regular";
  &.active ~ .main-header__nav-line {
    opacity: 1;
  }
  &:not(:last-child) {
    margin-right: 30px;
  }
  &:nth-child(1).active ~ .main-header__nav-line {
  	transform: translate3d(0, 0, 0);
    width: 59px;
  }
  &:nth-child(2).active ~ .main-header__nav-line {
  	transform: translate3d(89px, 0, 0);
    width: 93px;
  }
  &:nth-child(3).active ~ .main-header__nav-line {
  	transform: translate3d(213px, 0, 0);
    width: 78px;
  }
  &:nth-child(4).active ~ .main-header__nav-line {
  	transform: translate3d(320px, 0, 0);
    width: 43px;
  }
  &:nth-child(5).active ~ .main-header__nav-line {
	  transform: translate3d(394px, 0, 0);
    width: 55px;
  }
  @media screen and (max-width: $screen-sm-max) {
    float: none;
    text-align: center;
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}
.main-header__nav-line {
  position: absolute;
  top: 130%;
  left: 0;
  width: 59px;
  height: 3px;
  background-color: white;
  list-style: none;
  pointer-events: none;
  transition-property: width, transform;
	transition-timing-function: ease-in-out;
  opacity: 0;
}
.main-header__nav-link {
  position: relative;
  display: inline-block;
  color: white;
  font-family: "Poppins-Regular";
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  @media screen and (max-width: $screen-sm-max) {
    font-size: 22px;
    font-weight: 300;
  }
}
.main-header__nav-link--blue:hover {
  color: $vane-blue;
}
.head-section__bottom-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  @media screen and (max-height: 650px) {
    position: static;
  }
}
.head-section__text-wrap {
  position: relative;
  margin-bottom: 40px;
  padding-top: 240px;
  max-width: 380px;
  z-index: 1;
}
.head-section__text {
  margin-bottom: 30px;
  color: white;
  font-family: "Poppins-Regular";
  font-size: 20px;
  line-height: 1.33;
  font-weight: 400;
  @media screen and (max-height: 650px) {
    font-size: 14px;
    line-height: 1.4;
  }
}
.head-section__button-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
